import { motion } from "framer-motion";
import ProfilePic from '../assets/profile_pic.jpg';
import { Link } from 'react-router-dom';
import SocialMediaIcon from '../components/SocialMediaIcon.js'

const Home = () => {

    // SVG Paths for "Hi, I'm Elden."
    const letterPaths = [
        "M 2.64 3.5667 L 5.115 3.5667 L 5.115 7.6917 L 9.207 7.6917 L 9.207 3.5667 L 11.682 3.5667 L 11.682 15.1167 L 9.207 15.1167 L 9.207 10.1667 L 5.115 10.1667 L 5.115 15.1167 L 2.64 15.1167 Z",
        "M 13.6735 6.8666 L 16.1485 6.8666 L 16.1485 15.1167 L 13.6735 15.1167 Z M 14.9192 3.5337 C 15.2658 3.5337 15.5587 3.6533 15.7979 3.8925 C 16.0372 4.1318 16.1567 4.4247 16.1567 4.7712 C 16.1567 5.1231 16.0399 5.4174 15.8062 5.6539 C 15.5724 5.8904 15.2795 6.0087 14.9275 6.0087 C 14.57 6.0087 14.2702 5.8904 14.0282 5.6539 C 13.7863 5.4174 13.6653 5.1231 13.6653 4.7712 C 13.6653 4.4247 13.7863 4.1318 14.0282 3.8925 C 14.2702 3.6533 14.5673 3.5337 14.9192 3.5337 Z",
        "M 18.3258 13.2522 L 20.8009 13.2522 L 19.4643 16.0406 L 17.8144 16.0406 Z",
        "M 26.7261 3.5666 L 29.201 3.5666 L 29.201 15.1167 L 26.7261 15.1167 Z",
        "M 32.0276 3.5666 L 33.6776 3.5666 L 33.1661 7.8731 L 30.6911 7.8731 Z",
        "M 40.0673 6.6357 C 40.6173 6.6357 41.1233 6.7608 41.5853 7.011 C 42.0473 7.2613 42.4213 7.5954 42.7073 8.0134 C 42.9988 7.5954 43.3742 7.2613 43.8334 7.011 C 44.2927 6.7608 44.7945 6.6357 45.339 6.6357 C 45.933 6.6357 46.472 6.78 46.956 7.0688 C 47.44 7.3575 47.8264 7.7439 48.1151 8.2279 C 48.4039 8.7119 48.5483 9.2509 48.5483 9.8449 L 48.5483 15.1167 L 46.0733 15.1167 L 46.0733 10.5379 C 46.0733 10.2794 46.01 10.0457 45.8835 9.8367 C 45.757 9.6277 45.5892 9.4599 45.3803 9.3334 C 45.1713 9.2069 44.9375 9.1436 44.679 9.1436 C 44.4205 9.1436 44.1854 9.2069 43.9736 9.3334 C 43.7619 9.4599 43.5928 9.6277 43.4663 9.8367 C 43.3398 10.0457 43.2765 10.2794 43.2765 10.5379 L 43.2765 15.1167 L 40.8015 15.1167 L 40.8015 10.5379 C 40.8015 10.2794 40.7397 10.0457 40.6159 9.8367 C 40.4922 9.6277 40.3244 9.4599 40.1127 9.3334 C 39.9009 9.2069 39.6658 9.1436 39.4073 9.1436 C 39.1543 9.1436 38.9219 9.2069 38.7101 9.3334 C 38.4984 9.4599 38.3293 9.6277 38.2028 9.8367 C 38.0763 10.0457 38.013 10.2794 38.013 10.5379 L 38.013 15.1167 L 35.538 15.1167 L 35.538 6.8666 L 38.013 6.8666 L 38.013 7.3864 C 38.288 7.1499 38.6015 6.9657 38.9535 6.8337 C 39.3055 6.7016 39.6767 6.6357 40.0673 6.6357 Z",
        "M 54.075 3.5666 L 61.434 3.5666 L 61.434 6.0417 L 56.55 6.0417 L 56.55 7.6917 L 60.312 7.6917 L 60.312 10.1667 L 56.55 10.1667 L 56.55 12.6417 L 61.434 12.6417 L 61.434 15.1167 L 54.075 15.1167 Z",
        "M 63.2865 2.7416 L 65.7615 2.7416 L 65.7615 15.1167 L 63.2865 15.1167 Z",
        "M 71.62 9.0282 C 71.279 9.0282 70.9695 9.1162 70.692 9.2921 C 70.414 9.4681 70.194 9.7047 70.032 10.0016 C 69.8695 10.2987 69.7885 10.6287 69.7885 10.9916 C 69.7885 11.3546 69.871 11.6847 70.036 11.9817 C 70.201 12.2787 70.4225 12.5152 70.7 12.6912 C 70.978 12.8672 71.2845 12.9552 71.62 12.9552 C 71.961 12.9552 72.2715 12.8672 72.552 12.6912 C 72.8325 12.5152 73.0555 12.2787 73.2205 11.9817 C 73.3855 11.6847 73.468 11.3546 73.468 10.9916 C 73.468 10.6287 73.3855 10.2987 73.2205 10.0016 C 73.0555 9.7047 72.8325 9.4681 72.552 9.2921 C 72.2715 9.1162 71.961 9.0282 71.62 9.0282 Z M 73.6825 2.7416 L 76.1575 2.7416 L 76.1575 15.1167 L 73.6825 15.1167 L 73.6825 14.3577 C 73.4295 14.6437 73.1285 14.8774 72.779 15.0589 C 72.43 15.2404 72.0325 15.3312 71.587 15.3312 C 70.9875 15.3312 70.4265 15.2184 69.904 14.9929 C 69.3815 14.7674 68.921 14.4553 68.522 14.0565 C 68.1235 13.6578 67.811 13.1972 67.5855 12.6746 C 67.36 12.1522 67.2475 11.5912 67.2475 10.9916 C 67.2475 10.3922 67.36 9.8312 67.5855 9.3087 C 67.811 8.7862 68.1235 8.3255 68.522 7.9268 C 68.921 7.528 69.3815 7.2159 69.904 6.9904 C 70.4265 6.7649 70.9875 6.6522 71.587 6.6522 C 72.0325 6.6522 72.43 6.7415 72.779 6.9203 C 73.1285 7.099 73.4295 7.3342 73.6825 7.6257 Z",
        "M1637.77 175.613C1633.15 175.613 1628.78 176.658 1624.65 178.748 1620.53 180.838 1616.95 183.698 1613.93 187.328 1610.9 190.958 1608.56 195.138 1606.91 199.868L1668.79 199.868C1667.25 195.138 1664.94 190.958 1661.86 187.328 1658.78 183.698 1655.18 180.838 1651.05 178.748 1646.93 176.658 1642.5 175.613 1637.77 175.613ZM1637.77 132.713C1650.97 132.713 1663.04 135.6 1673.99 141.375 1684.93 147.15 1694.17 154.96 1701.71 164.805 1709.24 174.65 1714.6 185.733 1717.79 198.053 1720.98 210.373 1721.31 223.078 1718.78 236.168L1608.73 236.168C1610.27 240.018 1612.33 243.51 1614.92 246.645 1617.5 249.78 1620.69 252.283 1624.49 254.153 1628.28 256.023 1632.71 257.013 1637.77 257.123 1642.61 257.233 1647.09 256.188 1651.22 253.988 1655.34 251.788 1658.94 248.818 1662.02 245.078L1712.51 256.793C1705.8 271.423 1695.79 283.385 1682.48 292.68 1669.17 301.975 1654.27 306.623 1637.77 306.623 1622.59 306.623 1608.76 302.718 1596.27 294.908 1583.79 287.098 1573.83 276.593 1566.41 263.393 1558.98 250.193 1555.27 235.618 1555.27 219.668 1555.27 207.678 1557.41 196.43 1561.7 185.925 1565.99 175.42 1571.91 166.18 1579.44 158.205 1586.98 150.23 1595.75 143.988 1605.76 139.478 1615.77 134.968 1626.44 132.713 1637.77 132.713Z",
        "M 81.8885 8.7806 C 81.6575 8.7806 81.439 8.8329 81.2325 8.9374 C 81.0265 9.0419 80.8475 9.1849 80.6965 9.3664 C 80.545 9.5479 80.428 9.7569 80.3455 9.9934 L 83.4395 9.9934 C 83.3625 9.7569 83.247 9.5479 83.093 9.3664 C 82.939 9.1849 82.759 9.0419 82.5525 8.9374 C 82.3465 8.8329 82.125 8.7806 81.8885 8.7806 Z M 81.8885 6.6357 C 82.5485 6.6357 83.152 6.78 83.6995 7.0688 C 84.2465 7.3575 84.7085 7.748 85.0855 8.2403 C 85.462 8.7325 85.73 9.2866 85.8895 9.9027 C 86.049 10.5187 86.0655 11.1539 85.939 11.8084 L 80.4365 11.8084 C 80.5135 12.0009 80.6165 12.1755 80.746 12.3323 C 80.875 12.489 81.0345 12.6142 81.2245 12.7077 C 81.414 12.8012 81.6355 12.8506 81.8885 12.8561 C 82.1305 12.8617 82.3545 12.8094 82.561 12.6994 C 82.767 12.5894 82.947 12.4409 83.101 12.2539 L 85.6255 12.8397 C 85.29 13.5712 84.7895 14.1692 84.124 14.634 C 83.4585 15.0988 82.7135 15.3312 81.8885 15.3312 C 81.1295 15.3312 80.438 15.1359 79.8135 14.7454 C 79.1895 14.3549 78.6915 13.8297 78.3205 13.1696 C 77.949 12.5097 77.7635 11.7809 77.7635 10.9834 C 77.7635 10.3839 77.8705 9.8215 78.085 9.2963 C 78.2995 8.771 78.5955 8.309 78.972 7.9103 C 79.349 7.5115 79.7875 7.1994 80.288 6.9739 C 80.7885 6.7484 81.322 6.6357 81.8885 6.6357 Z",
        "M 91.8975 6.6357 C 92.4915 6.6357 93.0305 6.78 93.5145 7.0688 C 93.9985 7.3575 94.385 7.7439 94.6735 8.2279 C 94.9625 8.7119 95.107 9.2509 95.107 9.8449 L 95.107 15.1167 L 92.632 15.1167 L 92.632 10.5379 C 92.632 10.2794 92.5685 10.0442 92.442 9.8325 C 92.3155 9.6208 92.148 9.453 91.939 9.3293 C 91.73 9.2055 91.496 9.1436 91.2375 9.1436 C 90.979 9.1436 90.744 9.2055 90.532 9.3293 C 90.3205 9.453 90.1525 9.6208 90.029 9.8325 C 89.905 10.0442 89.8435 10.2794 89.8435 10.5379 L 89.8435 15.1167 L 87.3685 15.1167 L 87.36 6.8666 L 89.835 6.8666 L 89.8435 7.3864 C 90.1185 7.1499 90.432 6.9657 90.784 6.8337 C 91.136 6.7016 91.507 6.6357 91.8975 6.6357 Z",
        "M 97.6555 12.8561 C 97.9965 12.8561 98.288 12.9771 98.53 13.2191 C 98.772 13.4611 98.893 13.7526 98.893 14.0936 C 98.893 14.4401 98.772 14.733 98.53 14.9723 C 98.288 15.2115 97.9965 15.3311 97.6555 15.3311 C 97.309 15.3311 97.016 15.2115 96.777 14.9723 C 96.5375 14.733 96.418 14.4401 96.418 14.0936 C 96.418 13.7526 96.5375 13.4611 96.777 13.2191 C 97.016 12.9771 97.309 12.8561 97.6555 12.8561 Z"
    ];

    const draw = {
        hidden: { pathLength: 0, fill: 'rgba(0, 0, 0, 0)' },
        visible: (i) => {
            const delay = i * 0.25;
            return {
                pathLength: 1,
                fill: ['rgba(0, 0, 0, 0)', 'rgba(0, 0, 0, 1)'],
                transition: {
                    pathLength: { delay, type: 'spring', duration: 0.5, bounce: 0, ease: 'easeInOut' },
                    fill: { delay: delay + 0.5, duration: 1.5, ease: 'easeInOut' },
                },
            }
        },
    };

    // Variant object for starting the homepage
    const loadingVariants = {
        begin: { opacity: 0, y: -20 },
        end: { opacity: 1, y: 0, transition: { duration: 1, type: 'spring', damping:20, stiffness: 700 }}
    };

    // Variants for the social media bar
    const socialMediaVariants = {
        begin: { opacity: 0, y: 20},
        end: { opacity: 1, y: 0, transition: { duration: 2, delay: 3, ease: 'easeInOut' } }
    }

    // Props
    const hoverIcon = {
        y: [0, 30, -30, 0],
        opacity: [1, 0, 0, 1],
        transition: { duration: 0.5, ease: 'easeInOut' },
    }

    const urls = ["https://github.com/eldenyap48", "https://www.linkedin.com/in/eldenyap/", "https://www.instagram.com/yap_its_elden/", "https://twitter.com/EldenYap73632"];
    const webpages = ["github", "linkedin", "insta", "twitter"];

    return (
        <motion.article className="flex-1 flex flex-col items-center justify-center" initial="begin" animate="end" variants={loadingVariants} exit={{ opacity: 0, transition: { duration: 0.5 } }}>

            {/* Image, Title and Introduction */}
            <div className='flex flex-row gap-20 ml-20 mr-20 mb-20 items-center justify-center w-full' >
                <motion.img animate={{ transition: {duration: 2} }} className="w-64 h-64 object-cover rounded-full shadow-lg border-2 border-black" src={ProfilePic}/>

                <motion.div className="pb-5">
                    <motion.svg initial="hidden" animate="visible" width="800" height="175" viewBox="0 0 200 20">
                        <g transform="translate(0, -2) scale(1.5)">
                            {letterPaths.map((path, index) => (
                                <motion.path key={index} d={path} stroke="black" strokeWidth="0.1" variants={draw} custom={index}/>
                            ))}
                        </g>
                    </motion.svg>
                    <motion.p className="ml-3 text-2xl w-[800px]" animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={introduction}>
                        I'm a third-year college student at UC Berkeley studying Computer Science & Economics
                    </motion.p>
                </motion.div>
            </div>
            
            {/* Check out my projects */}
            <motion.div className='flex items-center justify-center w-full' transition={introduction} initial={{ opacity:0 }} animate={{ opacity: 1 }}>
                <motion.button whileHover={{ scale:1.1 }} className="text-3xl outline rounded-full p-4 hover:bg-teal/30 font-bold">
                    <Link to="/Projects">Check out my projects</Link>
                </motion.button>
            </motion.div>

            {/* Social media icons */}

            <motion.div className='absolute bottom-5 flex gap-2' variants={socialMediaVariants}>
                
                { urls.map((url, index) => (
                    <SocialMediaIcon hoverIcon={hoverIcon} link={url} webpage={webpages[index]} />
                ))}

            </motion.div>

        </motion.article>
    )
}

export default Home

const introduction = {
    duration: 2,
    delay: 3
};